<template>
  <div>
    <div class="page">
      <br />
      <div class="container-fluid">
      
        <v-row>
            <v-col cols="12">
                <v-card shaped elevation="1">
                    <h4 class="primary--text font-weight-bold pt-4 mt-0" style="text-align: center !important">
                        PREFEITURA MUNICIPAL DE PEDRAS DE FOGO - DIREÇÃO
                    </h4>
                    <h5 class="accent--text font-weight-bold pb-4 mb-0" style="text-align: center"> PAINEL DE INFORMAÇÕES DO DIRETOR </h5>
                </v-card>
            </v-col>
        
        </v-row>
  
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12' : dynamicCol">
            <filtroAno @messageFromChild="recall"></filtroAno>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12' : dynamicCol">
            <v-autocomplete
                v-model="IdUnidade"
                :items="unidadesList"
                item-text="Unidade"
                item-value="IdUnidade"
                label="Filtrar pela unidade"
                @input="recall($event)"
                hide-details
                :disabled="isDisabledAdmin"
                outlined
                dense
            ></v-autocomplete>
          </v-col>
        </v-row>
  
        <v-row width="50%" v-if="IdUnidade != null">
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Discentes por turno: Matutino</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ this.qtdTurnoMatutino }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-cloud-sun fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2" >
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Discentes por turno: Vespertino</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ this.qtdTurnoVespertino }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-sun fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Discentes por turno: Noturno</div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ this.qtdTurnoNoturno}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-moon fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Discentes por turno: Integral</div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ this.qtdTurnoIntegral }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-school fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-row>
  
        <v-row v-if="IdUnidade != null">
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Quantidade total de estudantes: CRECHE</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{this.qtdCreche}}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-child fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Quantidade total de estudantes: PRÉ ESCOLA</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{this.qtdPreEscola}}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-child fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2" >
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Quantidade total de estudantes: ANOS INICIAIS</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ this.qtdAnosIniciais }}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-users fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Quantidade total de estudantes: ANOS FINAIS</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{this.qtdAnosFinais}}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-graduation-cap fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-3 col-lg-3 mb-2'">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Quantidade total de estudantes: EJA</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{this.qtdEja}}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-graduation-cap fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-row>
  
  
        <v-row v-if="IdUnidade != null">
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Acompanhar lançamentos das avaliações dos docentes</h6>
              </div>
              <div class="card-body align-items-center justify-content-between">
                <frequencia :idAno="IdAno" :idUnidade="IdUnidade" :key="frequenciaKey"></frequencia>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Calendário bimestral do ano letivo</h6>
              </div>
                <div id="Calendario" class="card-body align-items-center justify-content-between">
                    <calendario :idAno="IdAno" :key="calendar"></calendario>
                </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
  
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Alunos matriculados por sexo</h6>
              </div>
              <div id="Pizza" class="card-body align-items-center justify-content-between">
                <alunosSexo :idAno="IdAno" :idUnidade="IdUnidade" :key="sexoalunos"></alunosSexo>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Ocupação das turmas - matutino</h6>
              </div>
              <div class="card-body">
                <ocupacaoGrafico :dados="dadosOcupacaoMatutino" :key="ocupacaoGraficoKey"></ocupacaoGrafico>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Ocupação das turmas - vespertino</h6>
              </div>
              <div class="card-body">
                <ocupacaoGrafico :dados="dadosOcupacaoVespertino" :key="ocupacaoGraficoKey"></ocupacaoGrafico>
              </div>
            </div>
          </div>
  
          <div :class="$vuetify.breakpoint.mdAndDown ? 'col-xl-12 col-md-12 mb-2' : 'col-xl-6 col-lg-6 mb-2'">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Ocupação das turmas - noturno</h6>
              </div>
              <div class="card-body">
                <ocupacaoGrafico :dados="dadosOcupacaoNoturno" :key="ocupacaoGraficoKey"></ocupacaoGrafico>
              </div>
            </div>
          </div>
  
          <div class="col-xl-12 col-md-12 mb-2">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Alunos fora de faixa</h6>
              </div>
              <div class="card-body">
                <alunosForaDeFaixa :idAno="IdAno" :idUnidade="IdUnidade" :key="foradefaixakey"></alunosForaDeFaixa>
              </div>
            </div>
          </div>
            
          <div class="col-xl-12 col-md-12 mb-2">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Docentes por segmento/turno</h6>
              </div>
              <div class="card-body">
                  <v-text-field
                      class="ml-3 mr-3"
                      v-model="searchProfessoresTurno"
                      prepend-icon="mdi-feature-search-outline"
                      label="Pesquisar"
                      clearable
                  ></v-text-field>
                  <v-data-table
                      :search="searchProfessoresTurno"
                      :headers="headersProfessoresTurno"
                      :items="professoresTurnoList"
                      hide-default-footer
                  ></v-data-table>
              </div>
            </div>
          </div>
  
  
        <v-row class="col-lg-12" v-if="IdUnidade != null">
          <v-col>
  
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Acompanhar registro da avaliação diagnóstica da educação infantil</h6>
            </div>
            <v-card>
              <v-text-field
                class="ml-3 mr-3"
                v-model="search"
                prepend-icon="mdi-feature-search-outline"
                label="Pesquisar"
                clearable
              ></v-text-field>
              <v-data-table
                :search="search"
                :headers="headers"
                :items="diagnosticaarray"
                hide-default-footer
              >
                <template v-slot:[`item.qtdAvaliacaoInicial`]= "{ item }">
                  <v-chip
                    :color="item.corInicial"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoInicial }}
                  </v-chip>
                </template>
  
                <template v-slot:[`item.qtdAvaliacaoProcessual`]= "{ item }">
                  <v-chip
                    :color="item.corProcessual"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoProcessual }}
                  </v-chip>
                </template>
                <template v-slot:[`item.qtdAvaliacaoFinal`]= "{ item }">
                  <v-chip
                    :color="item.corFinal"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoFinal }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
  
        <!-- <v-row class="col-lg-12" v-if="IdUnidade != null">
          <v-col>
  
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Avaliação diagnóstica ensino fundamental</h6>
            </div>
            <v-card
            cols="12">
              <v-col>
                <v-text-field
                  cols="6"
                  v-model="search"
                  prepend-icon="mdi-feature-search-outline"
                  label="Pesquisar"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  cols="6"
                  v-model="semestreArray"
                  label="Semestre"
                  :items="SemestreArray"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-data-table
                :search="search"
                :headers="headersProfessoresAvaliacao"
                :items="diagnosticaarray"
                :items-per-page="5"
              >
                <template v-slot:[`item.qtdAvaliacaoInicial`]= "{ item }">
                  <v-chip
                    :color="item.corInicial"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoInicial }}
                  </v-chip>
                </template>
  
                <template v-slot:[`item.qtdAvaliacaoProcessual`]= "{ item }">
                  <v-chip
                    :color="item.corProcessual"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoProcessual }}
                  </v-chip>
                </template>
                <template v-slot:[`item.qtdAvaliacaoFinal`]= "{ item }">
                  <v-chip
                    :color="item.corFinal"
                    class="black--text"
                  >
                  {{ item.qtdAvaliacaoFinal }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row> -->
  
  
          <div class="col-xl-12 col-lg-12" v-if="IdUnidade != null">
            <div class="card shadow mb-4">
  
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Ocupação das turmas - tabela</h6>
              </div>
              <ocupacaoTabela @abrirPopUp="modalOpen" :idAno="IdAno" :dados="dadosOcupacao" :key="ocupacaoTabelaKey"></ocupacaoTabela>
            </div>
          </div>
        </v-row>  
        
        <v-row v-if="IdUnidade != null">
          <div class="col-xl-12 col-lg-12">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Disponibilidade das turmas</h6>
              </div>
                <percentual :idAno="IdAno" :idUnidade="IdUnidade" :key="percentualKey"></percentual>
              <div class="card-body">
              </div>
            </div>
          </div>
        </v-row>
  
        <v-row>
          <div class="col-xl-12 col-md-12 mb-2">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Turmas multisseriadas da unidade</h6>
              </div>
              <div class="card-body">
                <multisserie :data="multisserieData" :key="multisseriadakey"></multisserie>
              </div>
            </div>
          </div>
        </v-row>
  
        <v-row v-if="IdUnidade != null">
          <div class="col-xl-12 col-lg-12">
            <div class="card shadow mb-4">
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Acompanhar desempenho dos alunos por bimestre</h6>
                <v-spacer></v-spacer>
                <filtroBimestre class="mr-5" @messageFromChild="getBimestre"></filtroBimestre>
                <filtroSerie class="mr-5" @messageFromChild="setSerie"></filtroSerie>
              </div>
                <situacaoBimestral :idUnidade="IdUnidade" :idAno="IdAno" :idSerie="IdSerie" :idBimestre="idBimestre" :key="bimestralkey" />
              <div class="card-body">
              </div>
            </div>
          </div>
        </v-row>
  
        <!--<v-row>
          <div class="col-xl-12 col-lg-12">
            <div class="card shadow mb-4">
  
              <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Lançamento da frequência pelos docentes</h6>
              </div>
  
              <div class="card-body py-5 align-items-center justify-content-between">
                <frequencia :idAno="IdAno" :idUnidade="IdUnidade" :key="frequenciaKey"></frequencia>
              </div>
            </div>
          </div>
        </v-row>-->
        </div>
          <div v-if="modalFlag">
            <popup @fecharModal="modalClose" :data="modalData"></popup>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import calendario from '@/components/educacao/EducacaoCalendarioBimestral.vue'
  import frequencia from '@/components/direcao/DirecaoFrequenciaBimestral.vue'
  import percentual from '@/components/direcao/DirecaoDisponibilidadeTurmas.vue'
  import situacaoBimestral from '@/components/direcao/situacaoBimestral.vue'
  import ocupacaoGrafico from '@/components/direcao/DirecaoOcupacaoTurmasGrafico.vue'
  import ocupacaoTabela from '@/components/direcao/DirecaoOcupacaoTurmasTabela.vue'
  import filtroAno from '@/components/folha/filtroFolhaPagamento.vue'
  import popup from '@/components/direcao/DirecaoModal.vue'
  import alunosSexo from '@/components/direcao/DirecaoAlunosPorSexo.vue'
  import alunosForaDeFaixa from '@/components/direcao/DirecaoAlunosForaDeFaixa.vue'
  import filtroSerie from '@/components/direcao/filtroSerie.vue'
  import filtroBimestre from '@/components/direcao/filtroBimestre.vue'
  import multisserie from '@/components/direcao/DirecaoTurmasMultisserieTabela.vue'
  
  export default {
    name: "AreaDiretor",
    components: {
      calendario,
      frequencia,
      percentual,
      ocupacaoGrafico,
      ocupacaoTabela,
      popup,
      filtroAno,
      alunosSexo,
      alunosForaDeFaixa,
      situacaoBimestral,
      filtroSerie,
      filtroBimestre,
      multisserie
    },
    data: () => {
      return {
        headers:[
              {text:'Nome do docente', value:'nomeDocente', width:"35%", align:"left"},
              {text:'Ano', value:'Serie', width:"10%", align:"center"},
              {text:'Turma', value:'TurmaDescricao', width:"10%", align:"center"},
              {text:'Quantidade de alunos', value:'qtdAlunos', width:"15%", align:"center"},
              {text:'Avaliação inicial', value:'qtdAvaliacaoInicial', width:"10%", align:"center"},
              {text:'Avaliação parcial', value:'qtdAvaliacaoProcessual', width:"10%", align:"center"},
              {text:'Avaliação final', value:'qtdAvaliacaoFinal', width:"10%", align:"center"},
          ],
        headersProfessoresAvaliacao:[
              {text:'Nome do docente', value:'nomeDocente', width:"20%", align:"left"},
              {text:'Turma', value:'Serie', width:"20%", align:"center"},
              {text:'Disciplina', value:'TurmaDescricao', width:"20%", align:"center"},
              {text:'Bimestre', value:'qtdAlunos', width:"10%", align:"center"},
              {text:'Planejamento', value:'qtdAvaliacaoInicial', width:"10%", align:"center"},
              {text:'Frequência', value:'qtdAvaliacaoProcessual', width:"10%", align:"center"},
              {text:'Notas', value:'qtdAvaliacaoFinal', width:"10%", align:"center"},
          ],
        headersProfessoresTurno:[
              {text:'Segmento', value:'Segmento', width:"40%", align:"left"},
              {text:'Turno', value:'Turno', width:"40%", align:"left"},
              {text:'Quantidade', value:'qtd', width:"20%", align:"center"}
          ],
        SemestreArray: ['1 semestre', '2 semestre', '3 semestre', '4 semestre'],
  
        items:[
          {
            nome: "professor alvino santos rocha silva filho",
            turma: "4º ano",
            qtdAlunos: 20,
            avInicial: 18,
            avMedia: 5,
            avFinal: 0,
          }
        ],
        dataModal: null,
        multisserieData: null,
        multisseriadakey: null,
        color: "",
        diagnosticaarray:[],
        dadosOcupacaoMatutino: [],
        dadosOcupacaoVespertino: [],
        dadosOcupacaoNoturno: [],
        dadosOcupacao: [],
        modalData: null,
        modalFlag: false,
        sexoalunos: null,
        ocupacaoGraficoKey: null,
        ocupacaoTabelaKey: null,
        percentualKey: null,
        calendar: null,
        frequenciaKey: null,
        foradefaixakey: null,
        quantitativoAnual: null,
        bool: false,
        anos: [],
        IdUnidade: 1,
        seriesList: [],
        IdSerie: 26,
        bimestralkey: null,
        idBimestre: "1º BIMESTRE",
        unidadesList: null,
        unidadesList2:null,
        dynamicCol: 6,
        showChartPercentual: false,
        showChartOcupacao: false,
        loading: false,
        search: null,
        qtdAlunos: null,
        qtdTurmas: null,
        qtdAlunosMasculino: null,
        qtdAlunosFeminino: null,
        qtdTurnoMatutino:null,
        qtdTurnoVespertino:null,
        qtdTurnoNoturno:null,
        qtdTurnoIntegral:null,
        qtdCreche: 0,
        qtdPreEscola: 0,
        qtdAnosIniciais: 0,
        qtdAnosFinais: 0,
        qtdEja: 0,
        visible: false,
        itemModal: [],
        aulasRegistred: 0,
        frequenciaRegistred: 0,
        IdAno: new Date().getFullYear(),
        acesso: false,
        professoresTurnoList: [],
        searchProfessoresTurno: "",
      }
    },
    computed: {
      isDisabledAdmin() {
        return this.acesso
      }
    },
    methods: {
      getOcupacao(){
        this.axios.get('turma/ocupacaolunosturma',{
          params: {
              idUnidade: this.IdUnidade,
              ano: this.IdAno
          }
        }).then(res => {
  
          this.dadosOcupacaoMatutino = res.data.filter(array => {
            if(array.Turno === 'MATUTINO'){
              return array
            }
          })
  
          this.dadosOcupacaoVespertino = res.data.filter(array => {
            if(array.Turno === 'VESPERTINO'){
              return array
            }
          })
  
          this.dadosOcupacaoNoturno = res.data.filter(array => {
            if(array.Turno === 'NOTURNO'){
              return array
            }
          })
  
          this.dadosOcupacao = res.data
          this.somarTurnos()
          this.somarEtapas()
          this.getMultisserie()
  
          this.foradefaixakey++
          this.ocupacaoGraficoKey++
          this.ocupacaoTabelaKey++
          this.quantitativoAnual++
          this.frequenciaKey++
          this.percentualKey++
          this.sexoalunos++
          this.calendar++
          this.bimestralkey++
  
        })
      },
  
      somarTurnos(){
        let turnoMatutino = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.Turno == 'MATUTINO')
          turnoMatutino += parseInt(ocupacao.QtdAlunos)
        });
  
        let turnoVespertino = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.Turno == 'VESPERTINO')
          turnoVespertino += parseInt(ocupacao.QtdAlunos)
        });
  
        let turnoNoturno = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.Turno == 'NOTURNO')
          turnoNoturno += parseInt(ocupacao.QtdAlunos)
        });
  
        let turnoIntegral = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.Turno == 'INTEGRAL')
          turnoIntegral += parseInt(ocupacao.QtdAlunos)
        });
  
        this.qtdTurnoMatutino   = turnoMatutino
        this.qtdTurnoVespertino = turnoVespertino
        this.qtdTurnoNoturno    = turnoNoturno
        this.qtdTurnoIntegral   = turnoIntegral
      },
  
      somarEtapas(){
        let etapaCreche = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.etapa == 'CRECHE')
            etapaCreche += parseInt(ocupacao.QtdAlunos)
        });
  
        let etapaPreescola = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.etapa == 'PRE-ESCOLA')
            etapaPreescola += parseInt(ocupacao.QtdAlunos)
        });
  
        let etapaAnosiniciais = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.etapa == 'ANOS INICIAIS')
            etapaAnosiniciais += parseInt(ocupacao.QtdAlunos)
        });
  
        let etapaAnosfinais = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.etapa == 'ANOS FINAIS')
            etapaAnosfinais += parseInt(ocupacao.QtdAlunos)
        });
  
        let etapaEja = 0;
        this.dadosOcupacao.forEach(ocupacao => { 
          if(ocupacao.etapa == 'EJA')
            etapaEja += parseInt(ocupacao.QtdAlunos)
        });
  
        this.qtdCreche = etapaCreche
        this.qtdPreEscola = etapaPreescola
        this.qtdAnosIniciais = etapaAnosiniciais
        this.qtdAnosFinais = etapaAnosfinais
        this.qtdEja = etapaEja
        
      },
  
      recall(value){  
        this.getTruth(value)        
        this.getOcupacao()        
        this.getMultisserie()
        this.getDiagnosticoAvaliacao(value)  
        this.getProfessorTurno()
      },
  
      getTruth(value){
        value > 2010 ? this.IdAno = value : this.IdUnidade = value
      },
  
      modalOpen(value){
        Object.defineProperty(value, 'ano', {
          enumerable: true,
          configurable: true,
          writable: true,
          value: this.IdAno
        })
        this.modalData = value
        this.modalFlag = true
      },
  
      modalClose(value){
        this.modalFlag = value
      },
  
      setSerie(value){
        this.IdSerie = value
        this.bimestralkey++
      },
  
      getBimestre(value){
        this.idBimestre = value
        this.bimestralkey++
      },
  
      getUnidades(){
        // this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
        this.axios.get('escola/user/escolas').then(res => {
          this.unidadesList = res.data.filter(unidade => {
            unidade.Unidade = this.prepareNameFilter(unidade.Unidade);
            if(unidade.IdUnidadeTipo === 1) {
              return unidade;
            }
          })
        })
      },
      limparFiltros(){
        this.IdAno = 2021
        this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
        this.dynamicCol = 6
      },
  
      async getDiagnosticoAvaliacao(){
        await this.axios.get('escola/escolas/diagnosticoavaliacoes',{
          params:{
            IdUnidade: this.IdUnidade,
            ano: this.IdAno
          }
        }).then(res => {
          this.diagnosticaarray = res.data
  
          var index;
          var corInicial, corProcessual, corFinal
  
          this.diagnosticaarray.forEach(element => {
            
  
            if (element.qtdAvaliacaoInicial == 0)
              corInicial = "red"
            else
              if (element.qtdAlunos > element.qtdAvaliacaoInicial)
                corInicial = "yellow"
              else
                corInicial = "green"
  
  
            if (element.qtdAvaliacaoProcessual == 0)
              corProcessual = "red"
            else
              if (element.qtdAlunos > element.qtdAvaliacaoProcessual)
                corProcessual = "yellow"
              else
                corProcessual = "green"   
  
  
            if (element.qtdAvaliacaoFinal == 0)
              corFinal = "red"
            else
              if (element.qtdAlunos > element.qtdAvaliacaoFinal)
                corFinal = "yellow"
              else
                corFinal = "green"
  
            // essa linha de código serve para pegar o array que vem do banco, e concatenar o resultado da operação acima para dentro desse array.
            index = this.diagnosticaarray.indexOf(element)
            this.diagnosticaarray[index] = {corInicial, corProcessual, corFinal,...element};
            
            
          });
        })
      },
      async getMultisserie(){
        await this.axios.get('turma/multisseries', { 
          params: {
            idUnidade: this.IdUnidade,
            ano: this.IdAno
          }
        }).then(res => {
          this.multisserieData = res.data
          this.multisseriadakey++
        })
      },
      getProfessorTurno(){
          this.axios.get('escola/escolas/professoresturno', { 
              params: {
              idUnidade: this.IdUnidade,
              ano: this.IdAno
              }
          }).then(res => {
              this.professoresTurnoList = res.data
          })
        },
      },
  
    mounted() {
      this.getUnidades()
      this.getOcupacao()
      this.getMultisserie()
      this.getDiagnosticoAvaliacao()
      this.getProfessorTurno()
    }
  }
  </script>
  
  <style>
    @media screen and (min-width: 1264px) and (max-width: 7680px) {
      #Calendario {
        height: 31rem;
        width: 29rem;
        margin-left: auto; 
        margin-right: auto;
        margin-bottom: -13rem;
        margin-top: 0rem;
      }
      #Frequencia {
        height: 31rem;
        width: 29rem;
        margin-left: auto; 
        margin-right: auto;
        margin-bottom: -13rem;
        margin-top: 0rem;
      }
      #Pizza {
        height: 615px;
        width: 24rem !important;
        margin-left: auto; 
        margin-right: auto;
        margin-bottom: -13rem;
        margin-top: auto;
      }
    }
  </style>