<template>
    <div id="chart">
        <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>

export default {
    props: {
        idAno: Number,
        idUnidade: Number
    },
    data() {
        return {
            series: [],
            chartOptions: {
                title: {
                    text: [],
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '14px',
                        fontWeight:  'bold',
                        fontFamily:  undefined,
                        color:  '#000000'
                    },
                },
                // chart: {
                //     width: 380,
                //     type: 'pie',
                // },
                labels: ['Masculino', 'Feminino'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: true,
                    position: 'bottom'
                }
            },
        }
    },
    methods: {
        getAlunosSexo(){
            this.axios.get('turma/alunossexo', {
                params: {
                    idUnidade: this.$props.idUnidade,
                    ano: this.$props.idAno
                }
            }).then(res => {
                let total = res.data[0].Quantidade + res.data[1].Quantidade
                this.series.push(res.data[0].Quantidade, res.data[1].Quantidade)
                this.chartOptions.title.text.push('Total de alunos matriculados: ' + total)
            })
        },
    },
    mounted(){
        this.getAlunosSexo()
    }
}
</script>