<template>
  <div id="chart">
    <v-row>
      <v-col v-for="(chart, index) in charts" :key="index" cols="6">
        <apexchart type="donut" :options="chart.options" :series="chart.series"></apexchart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    idAno: Number,
    idUnidade: Number
  },
  data() {
    return {
      charts: [
        { title: '1º Bimestre', series: [], options: {} },
        { title: '2º Bimestre', series: [], options: {} },
        { title: '3º Bimestre', series: [], options: {} },
        { title: '4º Bimestre', series: [], options: {} }
      ],
      baseChartOptions: {
        chart: {
          type: 'donut',
          width: 320,
        },
        labels: ['Avaliação registrada', 'Avaliação não registrada'],
        colors: ['#008C4A', '#FF4560'],
        dataLabels: {         
          style: {
            colors: ['#000000'],
            fontWeight: '300' 
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
      }
    }
  },
  methods: {
    getBimestreData(bimestreIndex) {
      this.axios.get('avaliacao/acompanhamentoavaliacao', {
        params: {
          intUnidade: this.idUnidade,
          intAnoLetivo: this.idAno,
          intTipoSegmento: 4,
          strDivisao: `%${bimestreIndex + 1}%`,
          intTipoRel: 1
        }
      }).then(res => {
        const data = res.data[0];
        const sim = parseInt(data.Sim, 10);
        const total = parseInt(data.Total, 10);
        this.charts[bimestreIndex].series.push(sim, (total - sim));
      })
    }
  },
  mounted() {
    this.charts.forEach((chart, index) => {
      // Assign base chart options and set the title for each bimestre
      chart.options = { ...this.baseChartOptions, title: { text: chart.title } };
      // Fetch data for each bimestre
      this.getBimestreData(index);
    });
  }
}
</script>

<style>
.apexcharts-canvas {
  max-width: 100% !important;
  height: auto !important;
}
</style>